<template>
    <component :is="layout" v-if="!isLoading"></component>
    <body v-else class="page-loading">
        <div class="lds-ripple"><div></div><div></div></div>
    </body>
</template>
<script>
export default {
    computed: {
        layout() {
            return this.$store.getters.layout + 'Layout';
        },
        isLoading() {
            return this.$store.state.pageLoading;
        }
    }
}
</script>
<style lang="less" scoped>
    .page-loading {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1B2A61;
        .lds-ripple {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
        }
        .lds-ripple div {
            position: absolute;
            border: 4px solid #fff;
            opacity: 1;
            border-radius: 50%;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        }
        .lds-ripple div:nth-child(2) {
            animation-delay: -0.5s;
        }
        @keyframes lds-ripple {
            0% {
                top: 36px;
                left: 36px;
                width: 0;
                height: 0;
                opacity: 1;
            }
            100% {
                top: 0px;
                left: 0px;
                width: 72px;
                height: 72px;
                opacity: 0;
            }
        }
    }
</style>
import FormattedInput from './FormattedInput';

export default {
    extends: FormattedInput,
    methods: {
        format(value) {
            let phone = (value || '').replace(/\D/igm, '').substr(0, 11);
            if (0 == phone.length) {
                return '';
            } else if (10 < phone.length) {
                phone = phone.replace(/^(\d{0,2})(\d{0,5})(\d{0,4})/igm, '($1) $2-$3');
            } else {
                phone = phone.replace(/^(\d{0,2})(\d{0,4})(\d{0,4})/igm, '($1) $2-$3');
            }
            phone = phone.replace(/\D+$/igm, '');
            return phone;
        }
    }
}
<template>
    <nav class="navigation">
        <div class="head"><Logo></Logo></div>
        <div class="content">
            <div>
                <router-link to="/perfil">
                    <div class="photo">
                        <div v-if="avatar != ''">
                            <figure
                                class="image is-1by1"
                                :style="`background-image: url('${avatar}')`"
                            >
                                <img src="/images/bulma/1by1.png" alt />
                            </figure>
                        </div>
                        <p>Olá, {{ name }}</p>
                    </div>
                </router-link>
                <ul class="menu">
                    <li>
                        <router-link to="/home" active-class="active">
                            <div class="wrap-image">
                                <img src="/images/icon-home.svg" alt />
                            </div>
                            Home
                        </router-link>
                    </li>
                    <li v-if="me.type == 'MASTER'">
                        <router-link to="/config" active-class="active">
                            <div class="wrap-image">
                                <i class="fa fa-cogs"></i>
                            </div>
                            Configurações
                        </router-link>
                    </li>
                    <li
                        v-if="
                            ['MASTER'].includes(me.type) ||
                            (me.permissions || []).includes('unity:read')
                        "
                    >
                        <router-link to="/unity" active-class="active">
                            <div class="wrap-image">
                                <i class="fas fa-sitemap"></i>
                            </div>
                            Unidades
                        </router-link>
                    </li>
                    <li
                        v-if="
                            ['ADMIN', 'MASTER', 'GERENTE'].includes(me.type) ||
                            (me.permissions || []).includes('users:read')
                        "
                    >
                        <router-link to="/user" active-class="active">
                            <div class="wrap-image">
                                <img src="/images/icon-usuarios.svg" alt />
                            </div>
                            Usuários
                        </router-link>
                    </li>
                    <li v-if="['MASTER', 'GERENTE'].includes(me.type) || (me.permissions||[]).includes('schedule:read')">
                        <router-link to="/schedule" active-class="active">
                            <div class="wrap-image">
                                <i class="far fa-calendar-alt"></i>
                            </div>
                            Escala
                        </router-link>
                    </li>
                    <li
                        v-if="
                            ['MASTER', 'GERENTE', 'CORRETOR'].includes(
                                me.type
                            ) || (me.permissions || []).includes('leads:read')
                        "
                    >
                        <router-link to="/leads" active-class="active">
                            <div class="wrap-image">
                                <img src="/images/icon-leads.svg" alt />
                            </div>
                            Leads
                        </router-link>
                    </li>

                    <li v-if="me.type == 'ATENDENTE'">
                        <a active-class="active" @click="showPopupLeads">
                            <div class="wrap-image">
                                <img src="/images/icon-leads.svg" alt />
                            </div>
                            Cadastrar Lead
                        </a>
                    </li>

                    <li v-if="me.type == 'ADMIN'">
                        <router-link to="/real-estate" active-class="active">
                            <div class="wrap-image">
                                <i class="fas fa-city"></i>
                            </div>
                            Imobiliárias
                        </router-link>
                    </li>
                    <li v-if="me.type == 'ADMIN'">
                        <router-link to="/mail-parser" active-class="active">
                            <div class="wrap-image">
                                <i class="fas fa-code"></i>
                            </div>
                            Mail Parsers
                        </router-link>
                    </li>
                    <li v-if="['MASTER', 'GERENTE'].includes(me.type)">
                        <router-link to="/platforms" active-class="active">
                            <div class="wrap-image">
                                <i class="fas fa-link"></i>
                            </div>
                            Origens
                        </router-link>
                    </li>

                    <li v-if="['MASTER', 'GERENTE'].includes(me.type)">
                        <router-link to="/reports" active-class="active">
                            <div class="wrap-image">
                                <img src="/images/icon-relatorios.svg" alt />
                            </div>
                            Relatórios
                        </router-link>
                    </li>

                    <li v-if="['MASTER'].includes(me.type)">
                        <router-link to="/api" active-class="active">
                            <div class="wrap-image">
                                <i class="fas fa-code"></i>
                            </div>
                            API
                        </router-link>
                    </li>
                    <li v-if="['MASTER'].includes(me.type)">
                        <router-link to="/facebook" active-class="active">
                            <div class="wrap-image">
                                <i class="fas fa-code"></i>
                            </div>
                            Facebook
                        </router-link>
                    </li>
                    <li
                        v-if="!me.online && canToggleOnline"
                        @click="toggleOnline"
                    >
                        <a href="javascript:void(0)" style="font-size:1.5em">
                            <div class="wrap-image">
                                <i class="fa fa-eye"></i>
                            </div>
                            Ficar Online
                        </a>
                    </li>
                    <li
                        v-if="me.online && canToggleOnline"
                        @click="toggleOnline"
                    >
                        <a href="javascript:void(0)">
                            <div class="wrap-image">
                                <i class="fa fa-eye-slash"></i>
                            </div>
                            Ficar Offline
                        </a>
                    </li>
                </ul>
            </div>
            <a
                href="javascript:void(0)"
                class="logout"
                @click.prevent.stop="logout"
            >
                <img src="/images/logout.svg" /> Sair
            </a>
        </div>
        <portal to="modal">
            <popup-cadastro
                v-show="showModal"
                @close="hidePopupLeads"
            ></popup-cadastro>
        </portal>
    </nav>
</template>
<script>
import Logo from "./Logo";
import PopupCadastro from "../pages/leads/popup-cadastro";

import SaveUserMutation from "../graphql/user/save.gql";

import axios from "../plugins/axios";

export default {
    components: {
        Logo,
        PopupCadastro,
    },
    data() {
        return {
            showModal: false,
        };
    },
    computed: {
        me() {
            const me = this.$store.state.me || {};
            return me;
        },
        canToggleOnline() {
            if (!["CORRETOR", "GERENTE"].includes(this.me.type)) {
                return false;
            }
            let modo_online = this.me.realEstate.modo_online;
            if (modo_online == 'INATIVO') {
                return false;
            }
            let escalas = [];
            this.me.unities.forEach(unity => {
                escalas.push(unity.department.scheduleType)
            });
            if (modo_online == 'ESCALA_DINAMICA' && !escalas.includes('DYNAMIC')) {
                return false;
            }
            if (modo_online == 'ESCALA_FIXA' && !escalas.includes('FIXED')) {
                return false;
            }
            if (
                !(this.me.permissions || []).includes("all") &&
                !(this.me.permissions || []).includes("users:toggleOnline")
            ) {
                return false;
            }
            return true;
        },
        isAdmin() {
            const me = this.me;
            if (!me) {
                return false;
            }
            return !!me.admin;
        },
        name() {
            let [name] = (this.me.name || "").split(/\s+/);
            return name;
        },
        avatar() {
            const me = this.me;
            if (!me) {
                return "";
            }
            return me.avatar;
        },
    },
    methods: {
        logout() {
            this.$store.dispatch("setAuthToken", { token: "" });
            this.$router.replace("/login");
        },
        hidePopupLeads() {
            this.showModal = false;
        },
        showPopupLeads() {
            this.showModal = true;
        },
        async toggleOnline() {
            let { id } = this.me;
            let online = !(this.me.online | 0);
            this.me.online = online;

            this.$apollo
                .mutate({
                    mutation: SaveUserMutation,
                    variables: {
                        user: {
                            id,
                            online,
                        },
                    },
                })
                .catch((err) => {
                    console.error(err);
                    this.me.online = !online;
                });
        },
    },
    //created() {},
    mounted() {
        if (!this.canToggleOnline) {
            return;
        }
        if (this.me.online) {
            return;
        }
        if (this.$store.state.asked) {
            return;
        }
        this.$store.state.asked = true;
        
        this.$confirm("", "Deseja ficar online?", "question", {
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            showCancelButton: true        
        }).then(resp => {
            this.toggleOnline();

        }).catch(err => {
            
        });
    },
};
</script>
<style lang="less">
nav .wrap-image {
    font-size: 18px;
}
nav a {
    cursor: pointer;
}
</style>
<style lang="less" scoped>
/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    
    border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}
nav.navigation {
    
    div.content {
        overflow-y: auto;
    
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //height: auto;
        padding-top: 1em;
        padding-bottom: 1em;
        box-sizing: border-box;

        

        a.logout {
            position: relative;
            bottom: unset;
            left: unset;
            align-items: flex-start;
            width: 100%;
            display: block;
            padding-left: 3.5em;
            margin-bottom: 1em;
        }
    }
}
</style>
import home from './home';
import config from './config';
import reports from './reports';
import login from './login';
import realEstate from './real-estate';
import mailParser from './mail-parser';
import user from './user';
import perfil from './perfil';
import unity from './unity';
import department from './department';
import leads from './leads';
import facebook from './facebook';
import schedule from './schedule';
import platforms from './platforms';
import api from './api';
import help from './help';

export default [
    home,
    config,
    reports,
    login,
    realEstate,
    mailParser,
    user,
    perfil,
    unity,
    department,
    leads,
    facebook,
    schedule,
    platforms,
    api,
    help
];

<template>
    <body class="help-page">
        <router-view></router-view>
        
    </body>
</template>
<script>

export default {
    components: {
    },
    destroyed() {
        let body =document.querySelector('body');
        if (!body) {
            body = document.createElement('body');
            body.classList.add('help-page');
            document.appendChild(body);
        }
    }
}
</script>
<template>
    <input type="text" v-model="val" />
</template>
<script>
export default {
    props: ['value'],
    data() {
        return {
            proxy: this.format(this.value)
        }
    },
    methods: {
        format(value) {
            return value;
        }
    },
    computed: {
        val: {
            get() {
                return this.format(this.proxy||'');
            },
            set(value) {
                let val = this.format(value);
                this.proxy = 'asdf';
                this.proxy = val;
                this.$emit('input', val);
            }
        }
    },
    watch: {
        value(value) {
            
            this.proxy = this.format(value);
        }
    }
}
</script>
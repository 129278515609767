export default {
    path: "/platforms",
    component: () => import("~/pages/platforms/index.vue"),
    children: [
        {
            path: "",
            component: () => import("~/pages/platforms/lista.vue"),
        },
        {
            path: "editar/:id",
            component: () => import("~/pages/platforms/editar.vue"),
            meta: {
                // requiresAdmin: true
            },
        },
        {
            path: "novo",
            component: () => import("~/pages/platforms/editar.vue"),
            meta: {
                // requiresAdmin: true
            },
        },
    ],
}
import axios from 'axios';


const backend_protocol = process.env.BACKEND_PROTOCOL||location.protocol.replace(/\:/igm,'');
const backend_hostname = process.env.BACKEND_HOST||location.hostname;
const backend_port = process.env.BACKEND_PORT||5000;

const instance = axios.create({
    baseURL: `${backend_protocol}://${backend_hostname}:${backend_port}/api/v1`
});

export default instance;
<template>
    <select v-model="selected">
        <option value="">Imobiliária</option>
        <option :value="imobiliaria.id" v-for="imobiliaria in imobiliarias" :key="imobiliaria.id">{{ imobiliaria.title }}</option>
    </select>
</template>
<script>

import LoadAllQuery from '../../graphql/real-estate/load-all.gql';

export default {
    props: ['value'],
    data() {
        return {
            proxy: this.value,
            imobiliarias: []
        };
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(value) {
                this.proxy = value;
                this.$emit('input', value);
            }
        }
    },
    methods: {
        fetchData() {
            const query = LoadAllQuery;
            const variables = {};
            this.$apollo.query({
                query,
                variables
            }).then(({ data, errors}) => {
                if (errors) {
                    let [error] = errors;
                    throw error;
                }
                let { realEstates } = data;
                this.imobiliarias = [...realEstates];
            }).catch(err => {
                console.error(err);
            });
        }
    },
    created() {
        this.fetchData();
    }
}
</script>
export default {
    path: "/unity",
    component: () => import("~/pages/unity/index.vue"),
    children: [
        {
            path: "",
            component: () => import("~/pages/unity/lista.vue"),
        },
        {
            path: "editar/:unity_id",
            component: () => import("~/pages/unity/editar.vue"),
            meta: {
                // requiresAdmin: true
            }
        },
        {
            path: "novo",
            component: () => import("~/pages/unity/editar.vue"),
            meta: {
                // requiresAdmin: true
            },
        },
    ],
}
<template>    
    <div class="sm-12">

        <h4 v-if="unities.length != 0">Unidades e Setores</h4>                
        <div class="row" v-if="unities.length != 0">
            <div class="sm-12 md-4" v-for="unity in Unities" :key="unity.id">
                <label>
                    
                    <strong>{{ unity.title }}</strong>
                </label>
                <label v-for="department in unity.departments" :key="department.id">
                    <input type="checkbox" :value="{ department: {id: department.id}, unity: {id:unity.id}}" v-model="selected">
                    {{ department.title }}
                </label>
            </div>
        </div>
    </div>
</template>
<script>

import LoadAllQuery from '../../graphql/unity/load-all.gql';
import gql from 'graphql-tag';

export default {
    props: ['value', 'realEstateId', 'gerente_id'],
    data() {
        return {
            gerente: null,
            proxy: this.value,
            unities: []
        };
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(value) {
                this.proxy = value;
                this.$emit('input', value);
            }
        },
        Unities() {
            if (!this.gerente_id) {
                return this.unities;
            }
            if (!this.gerente) {
                return [];
            }
            
            let unity_ids = this.gerente.unities.map(({ unity: { id }}) => id);
            let department_ids = this.gerente.unities.map(({ department: { id }}) => id);
            let unities = this.unities.map(unity => {

                let departments = unity.departments.filter(({ id }) => department_ids.includes(id));
                return {...unity, departments};
            });
            unities = unities.filter(({ id }) => unity_ids.includes(id));
            

            return unities;
        }
    },
    methods: {
        fetchData() {
            const query = LoadAllQuery;
            const variables = {
            };
            this.unities = [];
            if (!this.realEstateId) {
                return;
            }
            variables.filter = {
                realEstate: {
                    id: this.realEstateId
                }
            }
            this.$apollo.query({
                query,
                variables
            }).then(({ data, errors}) => {
                if (errors) {
                    let [error] = errors;
                    throw error;
                }
                let { unities } = data;
                this.unities = [...unities];
            }).catch(err => {
                console.error(err);
            });
        }
    },
    created() {
        this.fetchData();
    },
    watch: {
        realEstateId(value) {
            this.fetchData();
        },
        gerente_id: {
            immediate: true,
            async handler(gerente_id) {
                this.gerente = null;
                if (!gerente_id) {
                    return;
                }
                let query = gql`
                    query LoadGerente ($gerente_id: ID) {
                        user(id: $gerente_id) {
                            unities {
                                unity {
                                    id
                                }
                                department {
                                    id
                                }
                            }
                        }
                    }
                `;
                let variables = {
                    gerente_id: this.gerente_id
                };
                await this.$apollo.query({
                    query,
                    variables
                }).then(({ data, errors }) => {
                    if (errors) {
                        let [error] = errors;
                        throw error;
                    }
                    return data.user;
                }).then(gerente => {
                    this.gerente = gerente;
                }).catch(err => {
                    console.error(err);
                })
            }
        }
    }
}
</script>
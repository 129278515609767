export default {
    path: "/real-estate",
    component: () => import("~/pages/real-estate/index.vue"),
    children: [
        {
            path: "",
            component: () => import("~/pages/real-estate/lista.vue"),
            meta: {
                requiresAdmin: true,
            },
        },
        {
            path: "editar/:id",
            component: () => import("~/pages/real-estate/editar.vue"),
            meta: {
                requiresAdmin: true,
            },
        },
        {
            path: "novo",
            component: () => import("~/pages/real-estate/editar.vue"),
            meta: {
                requiresAdmin: true,
            },
        },
    ],
}
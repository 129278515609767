export default {
    path: "/user",
    component: () => import("~/pages/user/index.vue"),
    children: [
        {
            path: "",
            component: () => import("~/pages/user/lista.vue"),
        },
        {
            path: "editar/:id",
            component: () => import("~/pages/user/editar.vue"),
            meta: {
                //requiresAdmin: true
            },
        },
        {
            path: "novo",
            component: () => import("~/pages/user/editar.vue"),
            meta: {
                //requiresAdmin: true
            },
        },
    ],
}
import Cookies from 'js-cookie';
import query from '../graphql/me.gql';
import { apolloClient } from '../plugins/vue-apollo';
import gql from 'graphql-tag';

export default {
    setLayout({ commit }, layout) {
        commit('setLayout', layout);
    },
    setAuthToken({ commit }, {token, lembrar = false}) {
        commit('setAuthToken', token);
        let options = {
            path: '/'
        };
        if ('' == token) {
            Cookies.remove('auth-token', options);
            return;
        }
        if (lembrar) {
            options.expires = 3650;
        }
        Cookies.set('auth-token', token, options);
    },
    logout({ dispatch }) {
        return dispatch('setAuthToken', { token: ''});
    },
    setMe({ commit }, me) {
        commit('setMe', me);
    },
    async loadMe({ dispatch }) {
        let Me = null;
        try {
            const { data: { me } } = await apolloClient.query({
                query
            });            
            Me = me||null;
        } catch (err) {
            //dispatch('setMe', null)
            throw err;
        }
        await dispatch('setMe', Me);
        return Me;
    },
    setPageLoading({ commit }, pageLoading) {
        commit('setPageLoading', pageLoading);
    },
    loadHome({ commit }) {
        let query = gql`
            query {
                home {
                    id
                    texto
                }
            }
        `;
        return apolloClient.query({ query })
            .then(({ data, errors }) => {
                if (errors) {
                    let [error] = errors;
                    throw error;
                }
                return data.home;
            }).then(home => {
                return commit('setHome', home);
            });
    }
}
export default {
    path: "/schedule",
    component: () => import("~/pages/schedule/index.vue"),
    children: [
        {
            path: "",
            component: () => import("~/pages/schedule/lista.vue"),
        },
        {
            path: "editar/:unity_id/:department_id",
            component: () => import("~/pages/schedule/editar.vue"),
        },
    ],
}
import './css/base.less';
import './css/style.less';
import '@fortawesome/fontawesome-free/css/all.css';


import Vue from 'vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false

import apolloProvider from './plugins/vue-apollo';
import './plugins/drag-drop-polyfill';
 
import './plugins/portal-vue';



import VueSimpleAlert from 'vue-simple-alert';
Vue.use(VueSimpleAlert);



import DefaultLayout from './layouts/Default.vue';
import LoginLayout from './layouts/Login.vue';
import HelpLayout from './layouts/Help.vue';


import App from './App.vue';

Vue.component('DefaultLayout', DefaultLayout);
Vue.component('LoginLayout', LoginLayout);
Vue.component('HelpLayout', HelpLayout);

new Vue({
    router,
    store,
    apolloProvider,
    render: h => h(App)
}).$mount('body');

if (module.hot) {
    module.hot.accept();
}
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import Cookies from 'js-cookie';

import store from '../store';


Vue.use(VueRouter);

const router = new VueRouter({
    routes
});

export default router;

router.afterEach((to, from) => {
    store.state.showMenu = false;
    store.dispatch('setPageLoading', false);
})
router.beforeEach(async (to, from, next) => {
    
    store.dispatch('setPageLoading', true);
    let layout = 'Default';
    if (to && to.meta && to.meta.layout) {
        layout = to.meta.layout;
    }
    store.dispatch('setLayout', layout);
    
    let isPublic = false;
    if (to && to.meta && to.meta.isPublic) {
        isPublic = !!to.meta.isPublic;
    }

    if (to.query && to.query.token) {
        
        await store.dispatch('setAuthToken',{ token: to.query.token, lembrar: false});
        await store.commit('setAuthToken', to.query.token);
        return next('/perfil');
    }

    let token = store.state.token||'';
    

    let me;
    if ('' != token) {
        try {
            me = await store.dispatch('loadMe');
        } catch (err) {
            console.error(err);
            if (err.message.includes('status code 400')) {
                await store.dispatch('logout');
                token = '';
            }        
        }
    }

    if (!isPublic && !me) {        
        next('/login');
        return;        
    } else if (/^\/login/.exec(to.path) && !!me) {
        if ('ADMIN' == me.type) {
            next('/real-estate');
        } else if (['MASTER', 'GERENTE', 'CORRETOR'].includes(me.type)) {
            next('/leads');
        } else {
            next('/');
        }
        return;
    }

    let requiresAdmin = false;
    if (to && to.meta && to.meta.requiresAdmin) {
        requiresAdmin = !!to.meta.requiresAdmin;
    }
    

    if (requiresAdmin && (!me || !me.admin) ) {
        return next('/');
    }



    
    next();
})
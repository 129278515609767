<template>
    <tr>
        <td>
            
            <router-link :to="`/facebook/form/${form.id}`">
                {{ form.name }}
            </router-link>
        </td>
        
        <td align="center">
            <router-link :to="`/facebook/form/${form.id}`">
                {{ form.active ? 'Sim': 'Não'}}
            </router-link>
        </td>
        
        <td align="center">
            <router-link :to="`/facebook/form/${form.id}`">
                <i class="fa fa-cog"></i> Configurar
            </router-link>
        </td>
    </tr>
</template>
<script>
export default {
    props: ['form']
}
</script>
<style lang="less" scoped>
    tr {
        td {

            a {
                color:inherit;
            }
        }
    }
</style>
export default {
    path: "/leads",
    component: () => import("~/pages/leads/index.vue"),
    children: [
        {
            path: "",
            component: () => import("~/pages/leads/lista.vue"),
        },
        {
            path: "logs/:lead_id",
            component: () => import("~/pages/leads/logs.vue")
        }
    ],
}
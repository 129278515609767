import { apolloClient } from '../../../plugins/vue-apollo';
import loadAllUnitysQuery from "../../../graphql/unity/load-all.gql";

let loadingUnities = false;
export default {
    async loadUnities({ commit, dispatch }) {
        if (loadingUnities) {
            return;
        }
        loadingUnities = true;
        
        let query = loadAllUnitysQuery;
        let variables = {

        };

        await apolloClient.query({
            query,
            variables
        }).then(({ data, errors }) => {
            if (errors) {
                let [error] = errors;
                throw error;
            }
            return data.unities;
        }).then(unities => {
            commit('setUnities', unities);
        }).catch(err => {
            console.error(err);
        });
        loadingUnities = false;
    }
}
<template>
    
        <transition name="modal">
            <div class="modal">
                
                <div class="wrap-modal-content" @click="close">
                    <div class="content" @click.stop="() => {}">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </transition>
    
</template>
<script>
export default {
    methods: {
        close() {
            this.$emit('close');
        },
        closeHandler(evt) {            
            if (evt.key == 'Escape') {
                this.close();
            }
        }
    },
    mounted() {
        window.addEventListener('keyup', this.closeHandler);
    },
    destroyed() {
        window.removeEventListener('keyup', this.closeHandler);
    }
    
}
</script>

<style lang="less" scoped>
    * {
        box-sizing: border-box;
    }
    .modal {
        z-index: 999;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        overflow: auto;

        .wrap-modal-content {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 100%;
            padding: 0.25em;
            background-color: rgba(0,0,0,0.15);
            
            .content {
                border-radius: 0.25em;            
                background-color: white;            
                /*padding: 1em;*/
                
                transform: scale(1);
                box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.5);
            }
        }
        
    }

    

    @media screen and (max-width: 48em) {
        .modal {
            
            max-height: ~"calc(100vh - 70px)";
            top: 70px;
            
            .wrap-modal-content {
                min-height: 100%;
                
                
                
            }

        }
    }


    .modal-enter-active, .modal-leave-active {
        transition: all 0.3s ease-in-out;
        .wrap-modal-content .content {
            
            transition: all 0.3s ease-in-out;
        }
    }

    .modal-enter {
        opacity: 0;
        .wrap-modal-content .content {
            transform: scale(0.1);
        }
    }

    .modal-enter-to {
        .wrap-modal-content .content {
            transform: scale(1);
        }
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-leave {
        .wrap-modal-content .content {
            transform: scale(1);
        }
    }
    
    .modal-leave-to {        
        .wrap-modal-content .content {            
            transform: scale(1.1) ;
        }
    }
</style>
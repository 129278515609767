<template>
    <body>
        <div class="wrap-page">
            <Header></Header>
            <Nav></Nav>
            <section class="wrap-content">
                <NoticesBar></NoticesBar>
                <router-view></router-view>
            </section>
        </div>
        <portal-target name="modal"></portal-target>
    </body>
</template>
<script>
import Header from '../components/Header';
import Nav from '../components/Nav';
import NoticesBar from '../components/NoticesBar'
export default {
    components: {
        Header,
        Nav,
        NoticesBar
    },
    destroyed() {
        let body =document.querySelector('body');
        if (!body) {
            body = document.createElement('body');
            document.appendChild(body);
        }
    }
}
</script>
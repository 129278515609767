export default {
    path: "/department",
    component: () => import("~/pages/department/index.vue"),
    children: [
        {
            path: "",
            component: () => import("~/pages/department/lista.vue"),
        },
        {
            path: ":unity_id/novo",
            component: () => import("~/pages/department/editar.vue")
        },
        {
            path: "editar/:id",
            component: () => import("~/pages/department/editar.vue"),
            meta: {
                // requiresAdmin: true
            },
            children: [
                {
                    path: "",
                    component: () => import("~/pages/department/reply-times/lista.vue"),
                },
                {
                    path: "reply-times/novo",
                    component: () => import("~/pages/department/reply-times/novo.vue"),
                },
                {
                    path: "reply-times/:reply_time_id",
                    component: () => import("~/pages/department/reply-times/novo.vue"),
                },
            ]
        },
        {
            path: "novo",
            component: () => import("~/pages/department/editar.vue"),
            meta: {
                // requiresAdmin: true
            },
        },
    ],
}
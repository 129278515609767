import FormattedInput from './FormattedInput';

export default {
    extends: FormattedInput,
    methods: {
        format(value) {
            let cnpj = (value || '').replace(/\D/igm, '').substr(0, 14);
            if (0 == cnpj.length) {
                return '';
            } else {
                cnpj = cnpj.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/igm, '$1.$2.$3/$4-$5');
            }
            cnpj = cnpj.replace(/\D+$/igm, '');
            return cnpj;
        }
    }
}
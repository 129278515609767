import Vue from 'vue';
import VueApollo from 'vue-apollo';
//import ApolloClient from 'apollo-boost';

import { ApolloClient } from 'apollo-client';
//import { HttpLink } from 'apollo-link-http';
import { BatchHttpLink } from "apollo-link-batch-http";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities'

const backend_protocol = process.env.BACKEND_PROTOCOL || location.protocol.replace(/\:/igm, '');
const backend_hostname = process.env.BACKEND_HOST || location.hostname;
const backend_port = process.env.BACKEND_PORT || 5000;




let websocket_proto = 'ws';

if (backend_protocol == 'https') {
    websocket_proto = 'wss';
}

const wsLink = new WebSocketLink({
    uri: `${websocket_proto}://${backend_hostname}:${backend_port}/graphql`,
    options: {
        reconnect: true
    }
});

import Cookies from 'js-cookie';

Vue.use(VueApollo);





const cache = new InMemoryCache({
    addTypename: false
});
//const link = new HttpLink({
const httpLink = new BatchHttpLink({
    uri: `${backend_protocol}://${backend_hostname}:${backend_port}/graphql`,    
    fetch(uri, options) {
        const token = Cookies.get('auth-token', { path: '/' }) || '';
        options.credentials = 'omit';
        //options.headers['content-type'] = 'application/x-www-form-urlencoded';
        /*
        options.headers = {
            authorization: token ? `Bearer ${token}` : ''
        };
        */

        
        return fetch(`${uri}?token=${token}`, options);
    }
});

const link = split(({ query }) => {
    const definition = getMainDefinition(query);
    return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
    );
}, wsLink, httpLink);

export const apolloClient = new ApolloClient({
    cache,
    link: httpLink,
    addTypename: false,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache'
        },
        query: {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'no-cache'
        }
    },
    connectToDevTools: true
})

/*
export const apolloClient = new ApolloClient({
    uri: 'http://localhost:5000/graphql',
    cache: false,
    request: (operation) => {
        const token = Cookies.get('auth-token', { path: '/' })||'';
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}`: ''
            }
        });
    }
});
*/

export const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache'
        },
        query: {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'no-cache'
        }
    }
});

export default apolloProvider;

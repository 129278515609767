<template>
    <div class="notices-bar">
        <div class="row expanded">
            <div class="sm-12">
                <div class="inner">
                    <div class="warning" v-if="retidas">
                        <img src="/images/warning.svg" alt />
                        <p>
                            Você tem
                            <strong>{{ retidas }} leads retidos</strong>
                        </p>
                        <router-link to="/leads" class="default-button red">Gerenciar</router-link>
                    </div>
                    <div>
                        <a href="#" class="help">
                            <p>Ajuda</p>
                            <img src="/images/help.svg" alt />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            timer: null
        };
    },
    computed: {
        retidas() {
            if (!this.me) {
                return 0;
            }
            
            if (!['MANAGER', 'GERENTE'].includes(this.me.type)) {
                return 0;
            }

            return this.$store.state.leads.retainedLeads||0;
        },
        me() {
            return this.$store.state.me;
        }
    },
    methods: {
        loadData() {
            if (this.timer) {
                window.clearTimeout(this.timer);
            }        
            this.timer = window.setTimeout(async () => {
                await this.$store.dispatch('leads/loadRetainedLeads');
                this.loadData();
            }, 5000);        
        }
    },
    async mounted() {
        //this.loadData();
    },
    destroyed() {
        window.clearTimeout(this.timer);
    }
}
</script>
<template>
    <div style="display: flex; align-items:center;">
        <label class="image-input" :class="{ dragging }">
            <div class="drop-zone" v-on:drop.prevent="onDrop" v-on:dragover.prevent="onDragOver" v-on:dragleave="onDragLeave">
                <img :src="proxy" v-if="proxy!=''" />
                <span v-else>Selecionar Imagem</span>
            </div>
            <input type="file" accept="image/*" @change="onChange">
        </label>
        <div class="actions">
            <button class="default-button red" v-if="proxy!=''" @click="remover">Remover</button>

        </div>
    </div>
</template>
<script>
export default {
    props: ['value'],
    data() {
        return {
            dragging: false,
            loading: false,
            error: null,
            proxy: this.value||''
        }
    },
    methods: {
        onDrop(ev) {
            let files = []
            if (ev.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (ev.dataTransfer.items[i].kind === 'file') {
                        let file = ev.dataTransfer.items[i].getAsFile();
                        files.push(file);
                    }
                }
            } else {
                // Use DataTransfer interface to access the file(s)
                for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                    let file = ev.dataTransfer.files[i];
                    files.push(file);
                }
            }
            if (files.length) {
                let [file] = files;
                this.loadFile(file);
            }
            this.onDragLeave();
        },
        onDragOver() {
            this.dragging = true;
        },
        onDragLeave() {
            this.dragging = false;
        },
        remover() {
            this.proxy = '';
            this.$emit('input', '');
        },
        onChange(evt) {
            const el = evt.target;
            const files = el.files;
            let file = null;
            if (files.length != 0) {
                file = files[0];
                this.loadFile(file);
            } else {
                this.proxy = '';
                this.$emit('input', '');
            }
        },
        loadFile(file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.resize(reader.result);
                
            };
            reader.onerror = (err) => {
                this.error = err;
            }
            reader.readAsDataURL(file);
        },
        resize(url) {
            const img = document.createElement('img');
            

            img.onload = () => {
                const canvas = document.createElement('canvas');
                //let ctx = canvas.getContext('2d');
                //ctx.drawImage(img, 0, 0);
    
    
                const max_width = 240;
                const max_height = 240;
    
                let width = img.width;
                let height = img.height;
    
                if (width > height) {
                    if (width > max_width) {
                        height *= max_width / width;
                        width = max_width;
                    }
                } else {
                    if (height > max_height) {
                        width *= max_height / height;
                        height = max_height;
                    }
                }
    
                canvas.width = width;
                canvas.height = height;
    
                let ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
    
                const dataurl = canvas.toDataURL('image/jpeg', 0.85);
    
                this.proxy = dataurl;
                this.$emit('input', this.proxy);

            }

            img.onerror = err => console.error(err);

            img.src = url;

        }
    },
    watch: {
        value(value) {
            this.proxy = value||'';
        }
    }
}
</script>
<style lang="less" scoped>
    div.actions {
        padding: 1em;
    }
    .image-input {
        display: flex;
        height: auto!important;
        padding: 1em 0;

        [type=file] {
            display: none!important;
        }
        


        .drop-zone {
            width: 200px;
            height: 200px;
            background: white;
            border: 2px dashed gray;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                padding: 1em;

                @color: #efefef;

                background-color: #fff;
                background-image: linear-gradient(45deg, @color 25%, transparent 25%, transparent 75%, @color 75%, @color),
                linear-gradient(45deg, @color 25%, transparent 25%, transparent 75%, @color 75%, @color);
                background-size: 30px 30px;
                background-position: 0 0, 15px 15px;
            }
        }

        &.dragging {
            .drop-zone {
                border-color: red;
            }
        }
    }
</style>
export default {
    setUnities(state, unities) {
        state.unities = unities;
    },
    setPlatforms(state, platforms) {
        state.platforms = platforms;
    },
    setUsers(state, users) {
        state.users = users;
    },
    setLeads(state, leads) {
        state.leads = leads;        
    },
    addLeads(state, leads) {
        leads = [...state.leads, ...leads];
        let ids = leads.map(({ id }) => id);
        ids = [...new Set(ids)];
        leads = ids.map(id => leads.find(l => l.id == id));
        leads.sort((a, b) => {
            return b.date - a.date;
        })
        state.leads = leads;

    },
    setRetainedLeads(state, retained) {
        state.retainedLeads = retained;
    }
};
import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
    state,
    actions,
    mutations,
    getters,
    modules
});

const UserUpdate = async () => {
    await store.dispatch('loadMe');
    window.setTimeout(UserUpdate, 60000);
};

UserUpdate();

export default store;

//store.dispatch('leads/subscribe');

if (module.hot) {
    module.hot.accept('./actions', () => {
        const actions = require('./actions').default;
        store.hotUpdate({
            actions
        });
    });
    module.hot.accept('./mutations', () => {
        const mutations = require('./mutations').default;
        store.hotUpdate({
            mutations
        });
    });
    module.hot.accept('./getters', () => {
        const getters = require('./getters').default;
        store.hotUpdate({
            getters
        });
    });
    module.hot.accept('./modules', () => {
        const modules = require('./modules').default;
        store.hotUpdate({
            modules
        });
    });
}
<template>
    <header class="hidden-lg wrap-header-mobile">
        <div class="row">
            <div class="sm-2 md-1 flex-centered">
                <a href="javascript:void(0)" class="toggle-menu" @click="toggleMenu">
                    <i class="fas fa-bars"></i>
                </a>
            </div>
            <div class="sm-8 md-10">
                <Logo></Logo>
            </div>
        </div>
        <div class="mobile-menu" :class="{opened: $store.state.showMenu}">
            <div class="row">
                <div class="sm-2 md-1 flex-centered">
                    <a href="javascript:void(0)" class="toggle-menu"  @click="toggleMenu">
                        <i class="fas fa-times"></i>
                    </a>
                </div>
                <div class="sm-12">
                    <Nav></Nav>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
import Logo from './Logo';
import Nav from './Nav';
export default {
    components: {
        Logo,
        Nav
    },
    methods: {
        toggleMenu() {
            this.$store.state.showMenu = !this.$store.state.showMenu
        }
    }
}
</script>
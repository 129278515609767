import FormattedInput from './FormattedInput';

export default {
    extends: FormattedInput,
    methods: {
        format(value) {
            let cpf = (value || '').replace(/\D/igm, '').substr(0, 11);
            if (0 == cpf.length) {
                return '';
            } else {
                cpf = cpf.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/igm, '$1.$2.$3-$4');
            }
            cpf = cpf.replace(/\D+$/igm, '');
            return cpf;
        }
    }
}
export default {
    path: "/facebook",
    component: () => import("~/pages/facebook/index.vue"),
    children: [
        {
            path: "",
            component: () => import("~/pages/facebook/accounts.vue"),
        },
        {
            path: "politica-de-privacidade",
            component: () => import("~/pages/facebook/politica-de-privacidade.vue"),
        },
        {
            path: ":id",
            component: () => import("~/pages/facebook/account.vue")
        },
        {
            path: "page/:id",
            component: () => import("~/pages/facebook/page.vue"),
            children: [
                {
                    path: "",
                    component: () => import("~/pages/facebook/page/forms.vue")
                }
            ]
        },
        {
            path: "form/:id",
            component: () => import("~/pages/facebook/form.vue")
        }
    ],
}
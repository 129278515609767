import Cookies from 'js-cookie';
let token = Cookies.get('auth-token', { path: '/'})||'';



export default {
    layout: 'Default',
    token,
    me: null,
    pageLoading: false,
    home: null,
    asked: false,
    showMenu: false
};
<template>
    <div>
        <form @submit.prevent.stop="busca" class="filters-bar">
            <div class="row expanded">
                <div class="sm-12">
                    <ul class="sm-grid-1 md-grid-2 lg-grid-2">
                        <li>
                            <input
                                type="text"
                                v-model="keywords"
                                placeholder="Nome"
                            />
                        </li>
                        <li>
                            <button
                                type="submit"
                                class="default-button gradient"
                            >
                                <i class="fa fa-search"></i>
                                Filtrar
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
        <div class="wrap-table">
            <div class="row-expanded">
                <div class="sm-12">
                    <table cellspacing="0" class="sequential">
                        <thead>
                            <tr>
                                <th scope="col" style="width:auto" >Nome</th>
                                <th scope="col" width="150px" style="text-align:center">Configurado</th>
                                <th scope="col" class="small" style="width:200px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <Row
                                v-for="form in forms"
                                :form="form"
                                :key="form.id"
                            />
                        </tbody>
                    </table>
                    
                    <ul class="pagination">
                        <li v-for="page in pages" :key="`${page.page}${page.title}`">
                            
                            <router-link
                                v-if="page.type=='router-link'"
                                :active-class="'active'"
                                :to="{ path: page.path, query: page.query }"
                                v-html="page.title"
                                ></router-link>
                            <span v-else>...</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoadFormsQuery from "~/graphql/facebook/forms/forms.gql";
import Row from "./form-row.vue";
export default {
    //props: ["page_id"],
    components: {
        Row,
    },
    data() {
        return {
            loading: false,
            limit: +(this.$route.query.limit || 100),
            total: 0,
            page: +(this.$route.query.page || 1),
            query: "",
            keywords: "",
            forms: [],
        };
    },
    computed: {
        total_pages() {
            let total_pages = Math.ceil(this.total / this.limit);
            return total_pages;
        },
        first_page() {
            let { first_page } = this.page_index;
            return first_page;
        },
        last_page() {
            let { last_page } = this.page_index;
            return last_page;
        },
        page_index() {
            let total_pages = this.total_pages;                        
            let page = this.page;
            let fp = page - 5;
            if (fp < 1) {
                fp = 1;
            }
            let lp = fp + 10;
            if (lp > total_pages) {
                lp = total_pages;
                fp = lp - 10;
                if (fp < 1) {
                    fp = 1;
                }
            }

            return {
                first_page: fp,
                last_page: lp,
                page
            }
        },
        pages() {
            let total_pages = this.total_pages;            
            let pages = [];
            let { page, first_page:fp, last_page:lp} = this.page_index;
          

            if (page > 1) {
                pages.push({
                    type: 'router-link',
                    path: `/facebook/page/${this.$route.params.id}?page=${page -1}&limit=${this.limit}`,
                    params: this.$route.params,
                    query: {
                        page: page - 1,
                        limit: this.limit,
                    },
                    title: '&laquo;',
                });
            }

            if (this.first_page != 1) {
                pages.push({
                    type: 'a',
                    title: '...'
                })
            }

            for (let i = fp; i <= lp; i++) {
                pages.push({
                    type: 'router-link',
                    path: `/facebook/page/${this.$route.params.id}?page=${i}&limit=${this.limit}`,
                    params: this.$route.params,
                    query: {
                        page: i,
                        limit: this.limit,
                    },
                    title: i,
                });
            }

            if (this.last_page != total_pages) {
                pages.push({
                    type: 'a',
                    title: '...'
                })
            }

            if (page < total_pages) {
                pages.push({
                    type: 'router-link',
                    path: `/facebook/page/${this.$route.params.id}?page=${page + 1}&limit=${this.limit}`,
                    params: this.$route.params,
                    query: {
                        page: page + 1,
                        limit: this.limit,
                    },
                    title: '&raquo;',
                });
            }

            return pages;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
    },
    methods: {
        async navTo(page) {
            this.$router.push({
                path: page.path,
                query: page.query,
            });
        },
        async busca() {
            this.query = this.keywords;
            this.page = 1;
            this.forms = [];
            this.loadData();
        },
        async loadData() {
            try {
                if (this.loading) {
                    return;
                }
                let { page = 1, limit = 100 } = this.$route.query;
                let skip = Math.ceil(page - 1) * limit;
                limit = +limit;

                this.limit = limit;
                this.page = page;

                this.loading = true;
                let {
                    data: {
                        facebookPage: {
                            forms: { forms, total },
                        },
                    },
                } = await this.$apollo.query({
                    query: LoadFormsQuery,
                    variables: {
                        id: this.$route.params.id,
                        skip,
                        limit,
                        query: this.query,
                    },
                });
                this.forms = [...this.forms, ...forms];
                this.total = total;
            } catch (err) {
                console.error(err);
            }
            this.loading = false;
        },
    },
    watch: {
        page_id: {
            immediate: true,
            handler() {
                this.forms = [];
                this.page = 1;
                this.loadData();
            },
        },
    },
};
</script>
<style lang="less" scoped>
.wrap-table .row-expanded table.sequential {
    table-layout: auto;
}
.pagination {
    margin: 0;
    margin-top: 1em;
    margin-bottom: 1em;
    list-style: none;
    padding: 0;
    display: flex;
    li {
        a, span {
            display: flex;
            padding: 0.5em 1em;
            justify-content: center;
            align-items: center;
            margin: 0.25em;
            background-color: white;
            border: 1px solid rgba(27, 42, 97, 1);
            color: rgba(27, 42, 97, 1);
            &.active.router-link-exact-active {
                background-color: rgba(27, 42, 97, 1);
                color: white;                
            }
        }
        span {
            border: none;
        }
    }
}
</style>
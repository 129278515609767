<template>
    <label>
        <input type="checkbox" :value="value" v-model="checked"   />
        <i class="fas" :class="{'fa-lock': checked, 'fa-unlock': !checked}" ></i>
    </label>
</template>
<script>
export default {
    props: ['value'],
    data() {
        return {
            proxy: this.value
        }
    },
    computed: {
        checked: {
            get() {
                return this.value;
            },
            set(value) {
                this.proxy = value;
                this.$emit('input', value);
            }
        }
    }
}
</script>
<style lang="less" scoped>
    label {
        cursor: pointer;
        font-size: 1.5em;
    }
    [type=checkbox] {
        display: none;
    }
    .fas {
        transition: all 0.25s ease;
    }
    .fa-unlock {
        color: #10d19a;
    }
    .fa-lock {
        color: #C50E0E;
    }
</style>
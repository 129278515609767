export default {
    path: "/login",
    component: () => import("~/pages/login/login.vue"),
    children: [
        {
            path: "",
            component: () => import("~/pages/login/index.vue"),
            meta: {
                layout: "Login",
                isPublic: true,
            },
        },
        {
            path: "recover",
            component: () => import("~/pages/login/recover.vue"),
            meta: {
                layout: "Login",
                isPublic: true,
            },
        },
    ],
}
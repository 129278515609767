<template>
    <body>
        <div class="wrap-page">
            <Header></Header>           
            <div class="wrap-login">
                <div class="head">
                    <div class="row">
                        <div class="sm-12 md-6 lg-4 md-centered">
                            <Logo></Logo>
                        </div>
                    </div>
                </div> 
                <router-view></router-view>
                <div class="footer">
                    <div class="row">
                        <div class="sm-12 md-6 lg-4 md-centered">
                            <p>Desenvolvido pela <a href="https://www.studiogt.com.br" target="_blank">StudioGT</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>
</template>
<script>
import Header from '../components/Header';
import Nav from '../components/Nav';
import Logo from '../components/Logo';
export default {
    components: {
        Header,
        Nav,
        Logo
    },
    destroyed() {
        let body =document.querySelector('body');
        if (!body) {
            body = document.createElement('body');
            document.appendChild(body);
        }
    }
}
</script>
export default {
    setLayout(state, layout) {
        state.layout = layout;
    },
    setAuthToken(state, token) {
        state.token = token;
    },
    setMe(state, me) {
        state.me = me;
    },
    setPageLoading(state, pageLoading) {
        state.pageLoading = pageLoading;
    },
    setHome(state, home) {
        state.home = home;
    }
};